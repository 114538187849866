import React, { useEffect } from "react";
import "./App.css";

import {
  Card,
  WixDesignSystemProvider,
  Button,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { dashboard } from "@wix/dashboard";
import { createClient } from "@wix/sdk";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import { ReactTagManager } from "react-gtm-ts";

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [client, setClient] = React.useState(null as any);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    try {
      if (inIframe()) {
        var c = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: {
            dashboard,
          },
        });
        setClient(c);
      }
    } catch (error) {
      throw error;
    }
    // Check if the iframe has already been loaded on this page session
    if (sessionStorage.getItem("iframeLoaded")) {
      // Hide the current iframe by setting display to none
      document.body.style.display = "none"; // Hide the entire body of the iframe content
    } else {
      // Mark the iframe as loaded
      sessionStorage.setItem("iframeLoaded", "true");
    }
    // clean when unload
    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem("iframeLoaded");
    });
  }, []);

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingLayout
          size="tiny"
          title={t("feature-title")}
          description={t("feature-description")}
          actions={
            <Button
              size="small"
              skin="ai"
              suffixIcon={<Icons.AIFilledSmall />}
              as={"a"}
              href={`https://create.certifiedcode.us/#googtrans(en|${
                (new URLSearchParams(window.location.search).get("lang") ===
                "zh"
                  ? "zh-TW"
                  : new URLSearchParams(window.location.search).get("lang")) ||
                "en"
              })?utm_source=wix`}
              target={"_blank"}
            >
              {t("feature-share-button")}
            </Button>
          }
        />
      </Card>
      {/* </SidePanel> */}
    </WixDesignSystemProvider>
  );
}

export default App;
